<template lang="pug">

.widget()
  .widget-header
    h1.title Publisher Integration Test

  .widget-body
  
    div(v-if="shouldShowPhaseOne")
      loading(:active.sync="busy", :is-full-page="false", loader="spinner")

      select-offer(v-model="filters.offer", :multiple="false")
      select-publisher(v-model="filters.publisher", :multiple="false")
      .form-group
        label Link
        input.form-control(v-model="publisherLink")
      .form-group
        label OS 
        select.form-control(v-model="os")
          option(v-for="o in filters.oses", :value="o.value") {{ o.text }}
      .form-group
        label Country 
        select.form-control(v-model="country")
          option(v-for="o in filters.countries", :value="o.code") {{ o.name }}
      .form-group
        b-checkbox(type="checkbox", v-model="advancedPrivacy") Advanced Privacy
      button.btn.btn-primary(@click="handleTestRedirections", :disabled="!canTestRedirections") Test

      div(v-if="testRedirections.isDone" class="redirections-table")
        p Valid: {{ testRedirections.isValid ? 'Yes' : 'No' }}
        b-table(striped hover label="Redirections" :items="testRedirections.redirections")

    div(v-else-if="shouldShowPhaseTwo")
      div(v-if="testRedirections.isDone" class="redirections-table")
        p Valid: {{ testRedirections.isValid ? 'Yes' : 'No' }}
        b-table(striped hover label="Redirections" :items="testRedirections.redirections")

      .form-group
        label Event 
        select.form-control(v-model="testEvent.event")
          option(v-for="e in filters.offerEvents", :value="e.name") {{ e.label || e.name }}

      button.btn.btn-primary(@click="handleTestEvent", :disabled="!canTestEvent") Test

      div(v-if="testEvent.isDone", class="test-event-result")
        p Click ID:
          router-link(:to="{ name: 'click-view', params: { id: testEvent.clickId } }") {{ testEvent.clickId }}
        
        postbacks-table(:postbacks="testEvent.postbacks", :back-link="$route.fullPath", v-if="shouldShowPostbacks" )

</template>

<script>
import Vue from 'vue';

const possibleSteps = /** @type {const} */ ({
  testRedirections: 'test-redirections',
  testEvent: 'test-event',
});

/**
 * @typedef {Object} TestRedirectionsResponse
 * @property {boolean} success Is test successful
 * @property {string[]} redirections Redirections urls
 * @property {string} clickId Click ID
 * @property {number} eventsOfferId Offer ID to get events for
 */

/**
 * @typedef {Object} TestData
 * @property {string} publisherLink
 * @property {string} country
 * @property {string} os
 * @property {number} offerId
 * @property {boolean} advancedPrivacy
 */

/**
 * @param {TestData} data
 */
const test = async (data) => {
  const url = 'placement/test/redirections';
  /** @type {TestRedirectionsResponse} */
  const response = await Vue.ovReq.post(url, data);
  return response;
};

/** @param {nubmer} id Offer ID */
const getOffer = async (id) => {
  const response = await Vue.ovData.offer.get(id);
  return response;
};

/**
 * @typedef PublisherPostback
 * @property {string} click_id Click ID
 * @property {string} conversion_id
 * @property {number} event_id
 * @property {string} http_response Response JSON
 * @property {number} http_status
 * @property {number} id Postback ID
 * @property {string} last_retry
 * @property {string} last_retry_fmt
 * @property {number} publisher_id Publisher ID
 * @property {number} retries Number of retries
 * @property {number} status Postback status
 * @property {string} type Postback type (e.g "conversion")
 * @property {string} updatedAt
 * @property {string} url
 */

/**
 * @typedef ClickExtendedInfoResponse
 * @property {PublisherPostback[]} publisher_postbacks Postbacks
 */

/** @param {string} id Click ID */
const getClickExtendedInfo = async (id) => {
  const url = `click/getExtendedInfo/${id}`;
  /** @type {ClickExtendedInfoResponse} */
  const response = await Vue.ovReq.get(url);
  return response;
};

export default {
  async beforeRouteEnter(to, from, next) {
    const selectedPlacement = await Vue.ovData.placement.get(to.params.id);
    const {
      offer: { id: offerId },
      publisher: { id: publisherId },
    } = selectedPlacement;
    const [selectedPublisherOptions, selectedOfferOptions, countries] = await Promise.all([
      Vue.ovData.publisher.getSelectedOptions(publisherId),
      Vue.ovData.offer.getSelectedOptions(offerId),
      Vue.ovData.country.getOptions(),
    ]);

    next(async (vm) => {
      vm.filters.offer = selectedOfferOptions;
      vm.filters.publisher = selectedPublisherOptions;
      vm.filters.countries = countries;
      return vm;
    });
  },

  unmounted() {
    // Clear interval for extended click info fetching
    clearInterval(this.testEvent.postbackFetchInterval);
  },

  data() {
    return {
      busy: false,
      /** @type {'test-redirections' | 'test-event'} */
      step: possibleSteps.testRedirections,
      possibleSteps,
      filters: {
        offer: [],
        publisher: [],
        countries: [],
        oses: [
          { value: 'ios', text: 'iOS' },
          { value: 'android', text: 'Android' },
        ],
        /** @type {{ name: string; label: string }[]} */
        offerEvents: [],
      },
      publisherLink: '',
      country: '',
      os: '',
      advancedPrivacy: false,
      testRedirections: {
        isDone: false,
        /** @type {{ '#': number; redirect: string }[]} */
        redirections: [],
        isValid: false,
      },
      testEvent: {
        isDone: true,
        event: '',
        clickId: '',
        /** @type {PublisherPostback[]} */
        postbacks: [],
        postbackFetchInterval: null,
      },
    };
  },

  computed: {
    canTestRedirections() {
      const { publisherLink, country, os } = this;
      return publisherLink.length > 0 && country.length > 0 && os.length > 0;
    },
    canTestEvent() {
      const {
        testEvent: { event },
      } = this;
      return event !== '';
    },
    shouldShowPhaseOne() {
      return this.step === possibleSteps.testRedirections;
    },
    shouldShowPhaseTwo() {
      return this.step === possibleSteps.testEvent && this.testEvent.clickId !== '';
    },
    shouldShowPostbacks() {
      const {
        testEvent: { postbacks },
      } = this;
      return postbacks.length > 0;
    },
  },

  methods: {
    async handleTestRedirections() {
      const { publisherLink, country, os, filters } = this;
      const { offer } = filters;
      const data = {
        publisherLink,
        country,
        os,
        offerId: offer[0].id,
      };
      this.busy = true;
      try {
        this.testRedirections.isDone = false;
        const response = await test(data);
        this.testRedirections.isDone = true;
        this.testRedirections.redirections = response.redirections.map((r, index) => ({
          '#': index + 1,
          redirect: r,
        }));
        this.testRedirections.isValid = response.success;
        this.testEvent.clickId = response.clickId;
        this.step = possibleSteps.testEvent;

        const offer = await getOffer(response.eventsOfferId);
        this.filters.offerEvents = [
          { name: 'install', label: 'Install' },
          { name: 'reject', label: 'Reject' },
          ...offer.events,
        ];
      } finally {
        this.busy = false;
      }
    },

    async handleTestEvent() {
      const {
        testEvent: { event, clickId },
        advancedPrivacy,
      } = this;
      const data = { event, clickId, advancedPrivacy };
      this.busy = true;
      this.clearPostbackFetchInterval();
      try {
        await Vue.ovReq.post('placement/test/event', data);
        this.testEvent.isDone = true;

        // Fetch postbacks and set interval for fetching them
        const {
          testEvent: { clickId },
        } = this;
        const { publisher_postbacks } = await getClickExtendedInfo(clickId);
        this.testEvent.postbacks = publisher_postbacks;

        this.setPostbackFetchInterval();
      } finally {
        this.busy = false;
      }
    },

    setPostbackFetchInterval() {
      const {
        testEvent: { clickId },
      } = this;
      this.testEvent.postbackFetchInterval = setInterval(async () => {
        const { publisher_postbacks } = await getClickExtendedInfo(clickId);
        this.testEvent.postbacks = publisher_postbacks;
      }, 10000);
    },

    clearPostbackFetchInterval() {
      clearInterval(this.testEvent.postbackFetchInterval);
    },
  },
};
</script>

<style lang="scss" scoped>
.redirections-table {
  margin-top: 20px;
  max-width: 100%;
  overflow-wrap: anywhere;
}

.test-event-result {
  margin-top: 20px;
}
</style>
